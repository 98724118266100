@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Gotham-Book";
    src: url("../public/fonts/Gotham-Book.otf");
}

@font-face {
    font-family: 'Aristotelica';
    src: url("../public/fonts/Aristotelica Display DemiBold Trial.ttf");
}

@layer base {
    html {
        font-family: 'Gotham-Book', "Helvetica Neue", helvetica, arial, sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Aristotelica', "Helvetica Neue", helvetica, arial, sans-serif;
    }
}

.react-datepicker__triangle {
    left: -50px !important;
}
